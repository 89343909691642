import { render, staticRenderFns } from "./TaskMenu.vue?vue&type=template&id=31d575c3&scoped=true&"
import script from "./TaskMenu.vue?vue&type=script&lang=js&"
export * from "./TaskMenu.vue?vue&type=script&lang=js&"
import style0 from "./TaskMenu.vue?vue&type=style&index=0&id=31d575c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d575c3",
  null
  
)

export default component.exports
import {QMenu,QList,QItem,QItemSection,QChip,QIcon,QItemLabel,QForm,QInput,QBtn,QSeparator,QToggle,ClosePopup,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QMenu,QList,QItem,QItemSection,QChip,QIcon,QItemLabel,QForm,QInput,QBtn,QSeparator,QToggle})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup,Ripple})
