//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import api from '@/api/v3'
import { openHeadless, preventHtmlTags } from '@/utils'
import { format } from 'quasar'
import { tasksStore, teamsStore, uiStore, rootStore } from '@/store'

const { capitalize } = format

export default {
  name: 'TaskMenu',

  components: {
    TaskImportanceChip: () => import('@/components/Tasks/TaskImportanceChip'),
    TaskImportanceSelector: () => import('@/components/UI/TaskImportanceSelector'),
  },

  props: {
    jid: {
      type: String,
      required: true,
    },
    showColorsRulesOption: {
      type: Boolean,
      default: false,
    },
    showNotificationsOption: {
      type: Boolean,
      default: false,
    },
    showCountersOption: {
      type: Boolean,
      default: false,
    },
    showEditOption: {
      type: Boolean,
      default: false,
    },
    showPinOption: {
      type: Boolean,
      default: false,
    },
    showStatusOption: {
      type: Boolean,
      default: false,
    },
    // taskDeskFull | taskBarFull | fullTask
    location: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      complexity: null,
      changingComplexity: false,
      displayImportanceSelectThreshold: 6,
      isElectron: window.isElectron ?? false,
      taskNotifications: false,
      complexityError: '',
    }
  },

  watch: {
    jid: {
      handler: function () {
        this.complexity = this.task.complexity || null
      },
      immediate: true,
    },
    taskNotificationsFromStore: {
      immediate: true,
      handler (value) {
        this.taskNotifications = value
      },
    },
  },

  computed: {
    ...mapGetters([
      'currentChat',
      'profile',
    ]),
    currentMiddleColumnInstance () {
      return uiStore.getters.currentMiddleColumnInstance
    },
    currentTeam () {
      return teamsStore.getters.currentTeam
    },
    cardColorIsDefault () {
      return this.task.colorIndex === null
    },
    backgroundRegular () {
      return {
        backgroundColor: tasksStore.getters.cardColors(this.jid).regular,
      }
    },
    canCreateTask () {
      return this.profile.canCreateTask
    },
    task () {
      return tasksStore.state.data[this.jid]
    },
    taskNotificationsFromStore () {
      return this.task.notificationsEnabled
    },
    pinOption () {
      // if task is already closed and is not pinned (allow to unpin)
      if (
        tasksStore.getters.isClosed(this.jid) &&
        !this.task.pinned
      ) return null

      // component prop
      if (!this.showPinOption) return null

      const label = this.task.pinned
        ? this.$t('common.unpin')
        : this.$t('common.pin')
      const action = () => {
        // set pinned flag to task object to save
        const task = { pinned: !this.task.pinned }

        // set pinned_sort_ordering
        if (task.pinned) {
          let newOrder = 1 // default (first)

          // if there are pinned tasks already
          if (tasksStore.getters.pinned.length !== 0) {
            // get all their sort orderings
            const existingOrders = tasksStore.getters.pinned
              .filter(t => t.pinnedSortOrdering !== null)
              .map(t => t.pinnedSortOrdering)

            // set newOrder to be at the top
            newOrder = Math.min(...existingOrders) - 1
          }

          task.pinned_sort_ordering = newOrder
        } else {
          // have to reset it to null to prevent server -> 500
          // server doesn't like pinned: false with non-null ordering
          task.pinned_sort_ordering = null
        }

        tasksStore.actions.edit({ jid: this.jid, task, early: true })
        this.sendGoal(task.pinned ? 'pinTask' : 'unpinTask')
      }

      return { label, action }
    },
    editOption () {
      if (
        !this.showEditOption ||
        !tasksStore.getters.canEdit(this.jid)
      ) return null

      return {
        label: this.$t('tasks.edit'),
        action: () => {
          this.sendGoal('editTask')
          uiStore.actions.showModal({
            instance: 'new-task',
            payload: { jid: this.jid },
          })
        },
      }
    },
    statusExtendedOption () {
      if (
        !this.showStatusOption ||
        !tasksStore.getters.canChangeStatus(this.jid) ||
        !tasksStore.getters.hasExtraStatuses
      ) return null

      const label = this.$t('glossary.status')
      const chipLabel = tasksStore.state.statuses
        .find(s => s.name === this.task.taskStatus)
        .title
      const action = this.changeTaskCustomStatus
      const options = tasksStore.state.statuses
      const value = this.task.taskStatus

      return {
        label,
        chipLabel,
        action,
        options,
        value,
      }
    },
    statusOption () {
      if (
        !this.showStatusOption ||
        !tasksStore.getters.canChangeStatus(this.jid) ||
        tasksStore.getters.hasExtraStatuses
      ) return null
      const taskIsClosed = tasksStore.getters.isClosed(this.jid)
      const label = taskIsClosed
        ? this.$t('tasks.makeOpen')
        : this.$t('tasks.makeDone')
      const action = () => {
        if (!taskIsClosed) {
          this.sendGoal('taskDone')
        }
        this.changeTaskStatus()
      }

      return {
        label,
        action,
      }
    },
    importanceOption () {
      const label = this.$t('glossary.importance')
      const chipLabel = this.task.importance || this.$t('common.no')
      const canEdit = this.task.changeableFields.includes('importance')
      const value = this.task.importance
      const action = val => {
        this.sendGoal('changeImportance')

        tasksStore.actions.edit({
          jid: this.jid,
          task: { importance: val },
        })
        this.$refs.importanceMenu.hide()
        this.$refs.mainMenu.hide()
      }
      const caption = this.currentTeam.tasksImportanceRev
        ? this.$t('tasks.importanceCaption.descending')
        : this.$t('tasks.importanceCaption.ascending')

      return {
        action,
        canEdit,
        caption,
        chipLabel,
        label,
        value,
      }
    },
    openHeadlessOption () {
      return {
        label: this.$t('chattape.openInNewWindow'),
        action: () => {
          this.sendGoal('openTask')

          openHeadless(this.jid)
        },
      }
    },
    createCopyOption () {
      return {
        label: this.$t('tasks.createCopy'),
        action: () => {
          this.sendGoal('copyTask')

          uiStore.actions.showModal({
            instance: 'new-task',
            payload: {
              linkedMessages: [],
              cloneJid: this.jid,
            },
          })
        },
      }
    },
    deleteTaskOption () {
      return {
        label: this.$t('tasks.delete'),
        action: () => {
          this.sendGoal('deleteTask')

          this.deleteTask()
        },
      }
    },
    notificationsOption () {
      return {
        label: capitalize(this.$t('glossary.notifications')),
        caption: this.$t('profiles.notifications.captionDisabled'),
        disable: !rootStore.state.isNotificationsAllowed,
        value: this.task.notificationsEnabled,
        action: this.onChangeTaskNotifications,
      }
    },
    countersOption () {
      return {
        label: this.$t('chattape.unreads'),
        value: this.task.countersEnabled,
        action: value => this.setTaskParam(
          'counters_enabled',
          value,
        ),
      }
    },
  },

  methods: {
    async changeTaskStatus () {
      try {
        await tasksStore.actions.changeStatus({ jid: this.jid })
      } catch (e) {
        uiStore.actions.showModal({
          instance: 'universal-yes-no',
          payload: {
            title: this.$t('common.error'),
            noText: this.$t('common.close'),
            text: this.$t('tasks.errorChangingStatus'),
          },
        })
      }
    },
    async changeTaskCustomStatus (status) {
      if (status.name === this.task.taskStatus) return

      const payload = {
        jid: this.jid,
        status: status.name,
      }
      try {
        await tasksStore.actions.changeStatus(payload)
        this.sendGoal('changeTaskStatus')
      } catch (e) {
        uiStore.actions.showModal({
          instance: 'universal-yes-no',
          payload: {
            title: this.$t('common.error'),
            noText: this.$t('common.close'),
            text: this.$t('tasks.errorChangingStatus'),
          },
        })
      }
    },
    async deleteTask () {
      const discriptionEl = document.createElement('span')
      discriptionEl.innerHTML = this.$t('tasks.deleteQuestion', { task: preventHtmlTags(this.task?.displayName) })

      uiStore.actions.showModal({
        instance: 'universal-yes-no',
        payload: {
          title: this.$t('tasks.deleteTitle'),
          html: discriptionEl,
          yesText: this.$t('tasks.deleteYes'),
          yes: async () => {
            // reset MiddleColumn to prev instance only if remove current task and not from dashboard
            if (this.currentMiddleColumnInstance !== 'task-desk' && this.currentChat === this.jid) {
              uiStore.actions.resetMiddleColumnInstance(true)
            }
            await api.tasks.delete(this.jid)
          },
        },
      })
    },
    setTaskParam (key, value) {
      api.tasks.edit(this.jid, {
        [key]: value,
      })
    },
    async changeComplexity () {
      this.changingComplexity = true
      try {
        await tasksStore.actions.edit({
          jid: this.jid,
          task: { complexity: this.complexity },
        })
        this.$refs.complexityMenu && this.$refs.complexityMenu.hide()
        this.$refs.mainMenu && this.$refs.mainMenu.hide()
        this.sendGoal('changeTaskComplexity')
        this.complexityError = ''
      } catch (e) {
        this.complexityError = e.details.complexity
        console.warn(e)
      }
      this.changingComplexity = false
    },
    resetComplexity () {
      this.$refs.complexityMenu && this.$refs.complexityMenu.hide()
      this.complexity = this.task.complexity
      this.complexityError = ''
    },
    /**
     * Format goal and send
     * @param action  pinTask | unpinTask | editTask | taskDone | changeImportance | openTask |
     * copyTask | deleteTask | changeTaskStatus | changeTaskComplexity
     */
    sendGoal (action) {
      const actions = {
        unpinTask: 'Открепить задачу',
        pinTask: 'Закрепить задачу',
        editTask: 'Редактировать задачу',
        taskDone: 'Завершить задачу',
        changeImportance: 'Изменить приоритет задачи',
        openTask: 'Открыть задачу в новой вкладке',
        copyTask: 'Создать копию задачи',
        deleteTask: 'Удалить задачу',
        changeTaskStatus: 'Изменить статус задачи',
        changeTaskComplexity: 'Изменить сложность задачи',
      }

      const locations = {
        taskDeskFull: 'из карточки задачи на доске',
        taskDeskCompact: 'из карточки задачи на доске',
        taskBarFull: 'из карточки в правой колонке',
        taskBarCompact: 'из карточки в правой колонке',
        fullTask: 'из шторки задачи',
      }

      const goalString = `${actions[action]} - ${locations[this.location]}`
      window.goal('taskControls', { taskControls: goalString })
    },
    showColorsRules () {
      uiStore.actions.showModal({ instance: 'TasksColorsRules' })
    },
    onChangeTaskNotifications (value) {
      this.taskNotifications = value
      try {
        this.setTaskParam('notifications_enabled', value)
      } catch (e) {
        // TODO: refactor this
        setTimeout(() => {
          this.taskNotifications = this.taskNotificationsFromStore
        }, 300)
        throw e
      }
    },
  },
}
